<template>
  <v-dialog v-model="dialog" ref="offday" width="500">
  <v-card>
    <v-card-text>
      <v-container>
        <v-form ref="form" v-model="valid" lazy-validation>
          <div class="py-4 my-0 mx-0">
            <div cols="12" class="text-center text-heading-1">
              休暇追加
              <div>
                <v-btn
                  depressed
                  class="close-button"
                  fab
                  color="#E27C7C"
                  width="20"
                  height="20"
                  @click="closeModel"
                >
                  <v-icon size="14">mdi-close</v-icon>
                </v-btn>
              </div>
            </div>
          </div>
          <div class="m-0">
            <div cols="12" class="mt-0">
              <div class="form-header mb-2">従業員</div>
              <div>
                <v-select
                  :rules="userNameRule"
                  dense
                  outlined
                  v-model="formData.user_id"
                  :items="allUsers"
                  item-value="id"
                  placeholder="従業員 id / お名前"
                >
                  <template v-slot:item="{ item }">
                    {{ item.last_name }} {{ item.first_name }}
                  </template>
                  <template v-slot:selection="{ item }">
                    {{ item.last_name }} {{ item.first_name }}
                  </template>
                </v-select>
              </div>
            </div>

            <div cols="12">
              <div class="form-header mb-2">日付</div>
              <div>
                <v-dialog
                  ref="offDaysDate"
                  v-model="modal.offDaysDate"
                  :return-value.sync="formData.date"
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="formData.date"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      dense
                      hide-details
                      outlined
                      placeholder="0000 年 00 月 00 日"
                    >
                    </v-text-field>
                  </template>

                  <v-date-picker
                    v-model="formData.date"
                    :day-format="formatDate"
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="modal.offDaysDate = false"
                    >
                      キャンセル
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.offDaysDate.save(formData.date)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </div>
            </div>

            <div cols="12" class="mt-5">
              <div class="form-header mb-2">申請形態</div>
              <div>
                <v-select
                  dense
                  outlined
                  :items="offDayRange"
                  :rules="offRangeRule"
                  item-text="name"
                  item-value="id"
                  v-model="formData.off_range"
                  placeholder="申請形態を選択してください"
                ></v-select>
              </div>
            </div>

            <div cols="12">
              <div class="form-header mb-2">休暇申請ステータス</div>
              <div>
                <v-select
                  dense
                  outlined
                  :rules="statusRule"
                  item-text="name"
                  item-value="id"
                  :items="status"
                  placeholder="ステータスを選択してください"
                  v-model="formData.status"
                ></v-select>
              </div>
            </div>

            <v-col cols="12" class="error--text pa-0" v-if="alert">
              {{ alertMessage }}
            </v-col>

            <v-col cols="12">
              <v-row align="center">
                <v-col cols="6" class="offset-6 text-right">
                  <v-col cols="auto">
                    <v-btn
                      color="#4F55A7"
                      class="white--text"
                      width="100"
                      @click="save"
                      :loading="loading"
                      >登 録
                    </v-btn>
                  </v-col>
                </v-col>
              </v-row>
            </v-col>
          </div>
        </v-form>
      </v-container>
    </v-card-text>
  </v-card>
  </v-dialog>
</template>

<script>
import data from './data'
import dayjs from '@/plugins/dayjs'
export default {
  name: 'Dialog',
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      modal: {
        offDaysDate: false
      },
      valid: true,
      modalPickerDate: false,
      userNameRule: [v => !!v || 'ユーザーを選択してください'],
      // dateRule: [(v) => !!v || '日付を選択してください。'],
      offRangeRule: [v => v != null || '申請形態を選択してください'],
      statusRule: [v => v != null || 'ステータスを選択してください'],
      formData: {
        user_id: null,
        date: dayjs().format('YYYY-MM-DD'),
        off_range: null,
        status: null
      },
      offDayRange: [],
      status: [],
      alert: false,
      alertMessage: '',
      loading: false
    }
  },
  computed: {
    allUsers() {

      return this.$store.getters.allUsersList
    },
    dialog: {
      get() {
        return this.show
      },
      set(val) {
        return this.$emit('update:show', val)
      }
    }
  },
  watch: {
    dialog(newVal) {
      if (!newVal) {
        this.alert = false
      }
    }
  },
  methods: {
    formatDate(e) {
      return dayjs(e).format('D')
    },
    save() {
      this.alert = false
      this.loading = true
      let type = 'night_status'
      if (this.formData.off_range === 0) type = 'day_status'
      this.formData[type] = this.formData.status
      if (this.$refs.form.validate()) {
        this.$store
          .dispatch('OFF_DAY_CREATE', this.formData)
          .then(
            response => {
              if (response.data.status == 'success') {
                this.clearModelData()
                this.getDataFromApi()
                this.reset()
                this.$emit('close-dialog')
                this.$emit('alertMessage', {
                  message: '休暇申請を作成しました',
                  status: 'success'
                })
              }
              this.loading = false
            },
            error => {
              this.alert = true
              this.alertMessage = error?.data?.message
              this.loading = false
              throw error
            }
          )
          .finally(() => {
            this.loading = false
          })
      }
    },
    setTodayDate() {
      this.formData.date = dayjs(new Date().toLocaleDateString()).format(
        'YYYY-MM-DD'
      )
    },
    reset() {
      this.$refs.form.reset()
    },
    closeModel() {
      this.reset()
      this.$emit('close-dialog')
      this.setTodayDate()
    },

    clearModelData() {
      this.formData.user_id = null
      this.formData.date = null
      this.formData.off_range = null
      this.formData.status = null
    },
    async initTheAllUser() {
      await this.$store.dispatch('DROPDOWN_USER_LIST')
    },
    async getDataFromApi() {
      let params = {}
      params.paginate = 25
      params.filter = 'future_months'
      this.loading = true
      await this.$store.dispatch('OFF_DAY_GET_ALL' , params)
      this.loading = false
    }
  },
  created() {
    this.initTheAllUser()
    this.status = data.status
    this.offDayRange = data.range
  }
}
</script>

<style lang="scss" src="./Model.scss" scoped></style>
