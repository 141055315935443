<template>
  <div class="pa-9 pa-md-6 pa-lg-9">
    <v-container fluid>
      <!-- CREATE NEW  -->
      <offday :show.sync="offday" @close-dialog="offday = false" @alertMessage="showAlertMessage($event)"></offday>

      <!-- EDIT THE OFF DAYS -->
      <v-dialog ref="EditModel" v-model="EditModel" width="500">
        <EditModel @close-dialog="EditModel = false" @alertMessage="showAlertMessage($event)"
          @update:list="getDataFromApi"></EditModel>
      </v-dialog>

      <!-- PRINT THE SUCEESS AND ERROR MESSAGE -->

      <v-row class="px-2" v-if="message">
        <v-col>
          <v-alert dense text :type="messageStatus">
            {{ message }}
          </v-alert>
        </v-col>
      </v-row>

      <h3 class="page-title mb-7 font-weight-bold">休暇一覧</h3>

      <Toolbar :loading="loading" @click:search="updateFilter" />

      <v-row class="mt-4 mb-1">
        <!-- <v-col cols="2" class="d-flex justify-end">
          <v-checkbox class="mt-0 pt-0" hide-details="auto" v-model="checkbox" label=" 過去データの表示"
            @change="check($event)"></v-checkbox>
        </v-col> -->

        <v-col class="d-flex justify-end">
          <v-btn height="28" class="mr-4" text color="#4F55A7" min-width="100" @click="exportAsCSV">
            <v-icon left> mdi-file </v-icon>
            CSV出力
          </v-btn>
          <v-btn height="28" class="btn-new" color="#CFD3FE" depressed min-width="80" @click="addPhoto">
            <v-icon left> mdi-plus </v-icon>
            休暇
          </v-btn>
        </v-col>
      </v-row>

      <Table :headers="headers" :items="loading ? [] : offDays" show-select v-model="selected"
        :total-records="pagination ? pagination.records_total : null" search="test"
        :number-of-pages="pagination ? pagination.total_pages : null" :items-per-page="itemsPerPage" :footer="false"
        :item-class="itemRowBackground" @row-clicked="openEditModel" :options.sync="options" :loading="loading">
        <template v-slot:item.name="{ item }">
          {{ item.user?.last_name }} {{ item.user?.first_name }}
        </template>
        <template v-slot:item.status="{ item }">
          {{ item.status == 0 ? '申請中' : '' }}
          {{ item.status == 1 ? '要相談' : '' }}
          {{ item.status == 2 ? '承認済' : '' }}
          {{ item.status == 3 ? '却下' : '' }}
        </template>

        <template v-slot:item.off_range="{ item }">
          {{ item.type == 'day' ? '昼休' : '夜休' }}
        </template>

        <template v-slot:item.actions="{ item }">
          <v-btn color="primary mr-2" fab x-small @click.stop="openEditModel(item)">
            <v-icon small>mdi-pencil</v-icon>
          </v-btn>
        </template>
      </Table>

      <v-row>
        <v-col cols="3">
          <v-select color="#2F80ED" placeholder="ステータス" outlined dense hide-details v-model="actionStatus"
            :items="status" item-text="name" item-value="id">
          </v-select>
        </v-col>
        <v-col>
          <v-btn height="40" color="#CFD3FE" depressed @click="changeStatus">
            更新する
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Table from '@/components/admin/partials/Table/Table'
import offday from '@/components/admin/partials/OffDays/CreateModel'
import EditModel from '@/components/admin/partials/OffDays/EditModel'
import Toolbar from '@/components/admin/partials/OffDays/Toolbar'
import data from '@/components/admin/partials/OffDays/data'
import dayjs from '@/plugins/dayjs'
export default {
  name: 'List',
  components: { offday, EditModel, Toolbar, Table },
  data() {
    return {
      ids: [],
      options: {},
      itemsPerPage: 50,
      message: null,
      messageStatus: 'success',
      checkbox: false,
      selected: [],
      actionStatus: null,
      status: [],
      offday: false,
      EditModel: false,
      checkToday: 0,
      loading: false,
      headers: [
        {
          text: 'ID',
          align: 'center',
          value: 'idDb',
          sortable: true
        },
        {
          text: '休暇対象日',
          align: 'center',
          value: 'date',
          sortable: true
        },
        {
          text: '申請者',
          align: 'center',
          value: 'name',
          sortable: true
        },
        {
          text: 'ステータス',
          align: 'center',
          value: 'status',
          sortable: false
        },
        {
          text: '申請形態',
          align: 'center',
          value: 'off_range',
          sortable: false
        },

        {
          text: '',
          align: 'center',
          value: 'actions',
          sortable: false
        }
      ],
      items: [
        {
          id: 1,
          name: 'Test',
          date: '2021-01-01'
        }
      ],
      date: null,
      filter: 'future_months',
      user_id: null,
      day_status: null,
      from: null,
      to: null
    }
  },

  watch: {
    options: {
      handler() {
        this.getDataFromApi()
      },
      deep: true
    }
  },

  computed: {
    offDays() {
      return this.$store.getters.allOffDays
    },
    pagination() {
      return this.$store.getters.offDayPagination
    }
  },
  created() {
    this.getDataFromApi()
    this.status = data.status
    this.$store.dispatch('DROPDOWN_USER_LIST')
  },
  methods: {
    updateFilter(event) {
      this.date = event.date
      this.filter = event.filter
      this.from = event.from ?? null
      this.to = event.to ?? null
      this.user_id = event.user_id
      this.day_status = event.status
      this.getDataFromApi()
    },
    itemRowBackground(item) {
      if (item.status === 2) return 'colored'
    },
    changeStatus() {
      var ids = []
      this.selected.forEach(item => {
        ids.push(item.id)
      })

      this.$store
        .dispatch('OFF_DAY_CHANGES_STATUS', { ids, status: this.actionStatus })
        .then(
          response => {
            if (response.data.status == 'success') {
              this.getDataFromApi()
              this.$router.push({
                path: '/off-day/list',
                query: {
                  status: 'success',
                  message: response.data.data.message
                }
              })
            }
          },
          error => {
            throw error
          }
        )
    },

    async exportAsCSV() {
      this.$store.dispatch('OFF_DAY_EXPORT_AS_CSV').then(
        response => {
          let fileURL = window.URL.createObjectURL(
            new Blob([response.data], { type: 'application/csv' })
          )
          let fileLink = document.createElement('a')
          fileLink.href = fileURL
          fileLink.setAttribute('download', 'offDays.csv')
          document.body.appendChild(fileLink)
          fileLink.click()

          this.messageStatus = 'success'
          this.message = '休暇管理データをダウンロードしました。'
        },
        error => {
          throw error
        }
      )
    },
    async check(e) {
      let params = {}
      this.loading = true
      params.paginate = 25
      let now = dayjs()
      params.today = now.format('YYYY-MM-DD')
      if (this.checkbox) {
        params.checkToday = 1
      } else {
        params.checkToday = 0
      }

      await this.$store.dispatch('OFF_DAY_GET_ALL', params)
      this.loading = false
    },

    async getDataFromApi(params = {}) {
      this.loading = true

      let now = dayjs()

      // If filter by month is active, set now to the 21st of that month.
      if (params.filter_date) {
        now = dayjs(`${params.filter_date}-21`)
      } else if (now.date() >= 21) {
        now = now.date(21)
      } else {
        now = now.subtract(1, 'month').date(21)
      }

      params.paginate = 25
      params.itemsPerPage = this.options.itemsPerPage
      params.page = this.options.page
      params.sortBy = this.options.sortBy[0] || null
      params.sortDesc = this.options.sortDesc[0]
      params.today = now.format('YYYY-MM-DD')
      params.from = this.from
      params.date = this.date
      params.filter = this.filter
      params.user_id = this.user_id
      params.status = this.day_status
      params.to = this.to

      await this.$store.dispatch('OFF_DAY_GET_ALL', params)
      this.loading = false
    },
    async openEditModel(item) {
      this.$store.commit('SET_EDIT_DATA', item)
      await this.$store.dispatch('OFF_DAY_GET', item.id)
      this.EditModel = true
    },
    addPhoto() {
      this.offday = true
    },

    showAlertMessage(payload) {
      this.message = payload.message
      this.messageStatus = payload.status
    }
  }
}
</script>

<style src="./List.scss" lang="scss" scoped>
</style>
