<template>
  <v-card>
    <v-card-text>
      <v-container>
        <v-form ref="form" v-model="valid" lazy-validation>
          <div class="py-4 my-0 mx-0">
            <div cols="12" class="text-center text-heading-1">
              休暇編集
              <div>
                <v-btn
                  depressed
                  class="close-button"
                  fab
                  color="#E27C7C"
                  width="20"
                  height="20"
                  @click="$emit('close-dialog')"
                >
                  <v-icon size="14">mdi-close</v-icon>
                </v-btn>
              </div>
            </div>
          </div>
          <div class="m-0">
            <div cols="12" class="mt-0">
              <div class="form-header mb-2">従業員</div>
              <div>
                <v-select
                  :rules="userNameRule"
                  dense
                  outlined
                  v-model="offDay.user.id"
                  disabled
                  :items="allUsers"
                  item-text="first_name"
                  item-value="id"
                  placeholder="従業員 id / お名前"
                >
                  <template v-slot:item="{ item }">
                    {{ item.last_name }} {{ item.first_name }}
                  </template>
                  <template v-slot:selection="{ item }">
                    {{ item.last_name }} {{ item.first_name }}
                  </template>
                </v-select>
              </div>
            </div>

            <div cols="12">
              <div class="form-header mb-2">日付</div>
              <div>
                <v-dialog
                  ref="vehiclePurchaseDate"
                  v-model="modal.purchaseDate"
                  :return-value.sync="offDay.date"
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="offDay.date"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      dense
                      hide-details
                      outlined
                      placeholder="0000 年 00 月 00 日"
                    >
                    </v-text-field>
                  </template>

                  <v-date-picker
                    :day-format="formatDate"
                    v-model="offDay.date"
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="modal.purchaseDate = false"
                    >
                      キャンセル
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.vehiclePurchaseDate.save(offDay.date)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </div>
            </div>

            <div cols="12" class="mt-5">
              <div class="form-header mb-2">申請形態</div>
              <div>
                <v-select
                  dense
                  outlined
                  :items="offDayRange"
                  :rules="offRangeRule"
                  item-text="name"
                  item-value="id"
                  v-model="editType"
                  placeholder="申請形態を選択してください"
                ></v-select>
              </div>
            </div>

            <div cols="12">
              <div class="form-header mb-2">休暇申請ステータス</div>
              <div>
                <v-select
                  dense
                  outlined
                  :rules="statusRule"
                  item-text="name"
                  item-value="id"
                  placeholder="ステータスを選択してください"
                  :items="status"
                  v-model="offDayStatus"
                  return-object
                ></v-select>
              </div>
            </div>

            <v-col cols="12">
              <v-row align="center">
                <v-col cols="6">
                  <v-btn color="#AD4545" text @click="deleteOffDays">
                    <v-icon left>$trash</v-icon>
                    削除する
                  </v-btn>
                </v-col>
                <v-col cols="6" class="text-right">
                  <v-col cols="auto">
                    <v-btn
                      color="#4F55A7"
                      class="white--text"
                      width="100"
                      @click="save"
                      >更新する
                    </v-btn>
                  </v-col>
                </v-col>
              </v-row>
            </v-col>
          </div>
        </v-form>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import data from './data'
import dayjs from '@/plugins/dayjs'
export default {
  name: 'Dialog',
  data() {
    return {
      modal: {
        offDaysDate: false
      },
      valid: true,
      modalPickerDate: false,
      userNameRule: [v => !!v || 'ユーザーを選択してください'],
      // dateRule: [(v) => !!v || '日付を選択してください。'],
      offRangeRule: [v => v != null || '申請形態を選択してください'],
      statusRule: [v => v != null || 'ステータスを選択してください'],
      formData: {
        user_id: null,
        date: dayjs().format('YYYY-MM-DD'),
        off_range: null,
        status: null
      },
      offDayRange: [],
      status: [],
      offDayStatus: [],
      offRangeType: '',
      editType: '0'
    }
  },
  computed: {
    allUsers() {
      return this.$store.getters.allUsersList
    },
    offDay() {
      return this.$store.getters.offDay
    }
  },
  methods: {
    formatDate(e) {
      return dayjs(e).format('D')
    },
    save() {
      if (this.$refs.form.validate()) {
        this.$emit('close-dialog')
        this.offDay.type = this.editType
        this.offDay.status = this.offDayStatus.id
        this.offDay.editData = this.$store.getters.editData
        this.$store.dispatch('OFF_DAY_UPDATE', this.offDay).then(
          response => {
            if (response.data.status == 'success') {
              this.clearModelData()
              this.$emit('update:list')
              this.$emit('alertMessage', {
                message: '休暇申請を更新しました。',
                status: 'success'
              })
            }
          },
          error => {
            throw error
          }
        )
      }
    },
    deleteOffDays() {
      this.$emit('close-dialog')
      this.$store
        .dispatch('OFF_DAY_REMOVE', { id: this.offDay.id, type: this.editType })
        .then(
          response => {
            if (response.data.status == 'success') {
              this.$emit('update:list')
              this.$emit('alertMessage', {
                message: '休暇申請を削除しました。',
                status: 'success'
              })
            }
          },
          error => {
            throw error
          }
        )
    },
    clearModelData() {
      this.formData.user_id = null
      this.formData.date = null
      this.formData.off_range = null
      this.formData.status = null
    },
    async initTheAllUser() {
      await this.$store.dispatch('DROPDOWN_USER_LIST', { with_trashed: true})
    },
    setType(type) {
      this.editType = type
    },
    setEditType() {
      if (this.$store.getters.editData.type == 'day') this.editType = 0
      else this.editType = 1
    },
    offDayStatusSet() {
      this.setEditType()

      if (this.editType == '0') {
        return data.status[this.offDay.day_status]
      }

      if (this.editType == '1') {
        return data.status[this.offDay.night_status]
      }

      return ''
    },
    offRangeTypeSet() {
      if (this.editType == 'day') {
        return 0
      }

      if (this.editType == 'night') {
        return 1
      }

      return ''
    }
  },
  watch: {
    offDay() {
      this.offDayStatus = this.offDayStatusSet()
    },
    editData(val) {

      this.editType = val.type
    }
  },
  created() {
    this.initTheAllUser()
    this.status = data.status
    this.offDayRange = data.range
    this.setEditType()
    this.offDayStatus = this.offDayStatusSet()

  }
}
</script>

<style lang="scss" src="./Model.scss" scoped></style>
