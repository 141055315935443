<template>
  <div class="">
    <div class="buttons d-flex mb-3">
      <v-btn depressed class="mr-2" :class="active_filter == 'future_months' ? 'active' : ''"
        @click="filterTable('future_months')">
        本日以降
      </v-btn>
      <v-btn depressed class="mr-2" :class="active_filter == 'past_months' ? 'active' : ''"
        @click="filterTable('past_months')">
        過去
      </v-btn>
      <v-btn depressed class="mr-2" :class="active_filter == 'all' ? 'active' : ''" @click="filterTable('all')">
        全て
      </v-btn>
    </div>
    <v-expansion-panels>
      <v-expansion-panel class="expasion-panel">
        <v-expansion-panel-header class="expansion-header">
          <h2 class="mb-0 font-weight-bold"> 詳細設定</h2>
          <template v-slot:actions>
            <v-icon class="header-icon">
              $expand
            </v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row align="center">
            <v-col cols="6">
              <div class="field d-flex mb-5">
                <div class="v-input__prepend-outer"> 対象月 </div>
                <v-dialog ref="dialogMonth" v-model="modal.filterDate" :return-value.sync="formData.filter_date"
                  width="290px" @click:outside="$refs.dialogMonth.save(formData.filter_date)">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="datePlaceholder" readonly color="#2F80ED" background-color="#ffffff"
                      v-bind="attrs" v-on="on" dense hide-details outlined placeholder="すべて"
                      class="custom-placeholer-color">
                    </v-text-field>
                  </template>
                  <v-date-picker v-model="formData.filter_date" placeholder="test" :min="min_month" :max="max_month"
                    type="month">
                  </v-date-picker>
                </v-dialog>
              </div>

              <v-select background-color="#ffffff" class="select mb-5" color="#2F80ED" placeholder="全員" outlined dense
                :items="userPreDefine.concat(allUsers)" v-model="formData.user_id" item-text="first_name"
                item-value="id" hide-details>
                <template v-slot:item="{ item }">
                  {{  item.last_name  }} {{  item.first_name  }}
                </template>
                <template v-slot:selection="{ item }">
                  {{  item.last_name  }} {{  item.first_name  }}
                </template>
                <template v-slot:prepend>
                  <p class="field-label">従業員</p>
                </template>
              </v-select>

              <v-select background-color="#ffffff" class="select mb-5" color="#2F80ED" placeholder="ステータス" outlined
                item-text="name" item-value="id" :items="status" v-model="formData.status" dense hide-details>
                <template v-slot:prepend>
                  <p class="field-label">ステータス</p>
                </template>
              </v-select>
            </v-col>

            <v-col cols="6">
              <!-- <v-btn class="clear-btn" @click="reset" plain :disabled="loading">
                クリア
              </v-btn> -->

              <v-btn color="#2F80ED" width="112px" depressed class="btn-search" @click="search" :loading="loading">
                <v-icon left color="white"> mdi-magnify </v-icon>
                検索
              </v-btn>
            </v-col>

            <!-- <v-col cols="3">

            </v-col> -->
            <!-- <v-col cols="3">

            </v-col> -->

            <!-- <v-col cols="1" class="text-right">
              
            </v-col> -->

            <!-- <v-col cols="2" class="text-right">
              
            </v-col> -->
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import data from './data'
import dayjs from '@/plugins/dayjs'
export default {
  name: 'Toolbar',
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      modal: {
        filterDate: false,
        inspectionDate: false
      },
      formData: {
        filter_date: null,
        from: '',
        to: '',
        user_id: null,
        status: null,
        year: null,
        today: dayjs().format('YYYY-MM-DD'),
        checkToday: false,
        date: null
      },
      userPreDefine: [
        {
          id: null,
          last_name: 'すべて'
        }
      ],
      statusPreDefine: [
        {
          id: 0,
          status: 'すべて'
        }
      ],
      status: [
        {
          id: null,
          name: 'すべて'
        },
        {
          id: 0,
          name: '申請中'
        },
        {
          id: 1,
          name: '要相談'
        },
        {
          id: 2,
          name: '承認済'
        },
        {
          id: 3,
          name: '却下'
        }
      ],
      max_month: null,
      min_month: new Date().getFullYear() + "-" + dayjs().format('MM'),
      active_filter: 'future_months',
      datePlaceholder: 'すべて'
    }
  },

  computed: {
    allUsers() {
      return this.$store.getters.allUsersList
    }
  },
  watch: {
    'formData.filter_date': function() {
      this.datePlaceholder = this.formData.filter_date
    }
  },

  methods: {
    updatePlaceholder() {
      this.modal.filterDate = false
      this.datePlaceholder = this.formData.filter_date
    },

    filterTable(limit) {
      this.active_filter = limit
      let dateObj = new Date();
      let year = dateObj.getFullYear()
      let month = dayjs().format('MM')
      this.datePlaceholder = 'すべて'
      if (limit == "future_months") {
        this.min_month = year + "-" + month
        this.max_month = null
        this.formData.filter_date = null
        this.formData.to = null
        this.formData.from = null
      } else if (limit == "past_months") {
        let past_month = (month - 1) < 10 ? '0' + (month - 1) : month - 1
        this.max_month = year + "-" + past_month
        this.min_month = null
        this.formData.filter_date = null
        this.formData.to = null
        this.formData.from = null
      } else {
        this.max_month = null
        this.min_month = null
        this.formData.filter_date = null
        this.formData.to = null
        this.formData.from = null
      }
      this.search()
    },

    reset() {
      this.formData.filter_date = dayjs().format('YYYY-MM')
      this.formData.user_id = null
      this.formData.status = null
      this.search()
    },
    async search() {
      const date = dayjs(`${this.formData.filter_date}-20`)
      if(this.formData.filter_date){
      this.formData.to = date.format('YYYY-MM-DD')
      this.formData.from = date
        .subtract(1, 'month')
        .date(21)
        .format('YYYY-MM-DD')
      }
      this.formData.filter = this.active_filter
      this.formData.date = this.formData.filter_date

      this.$emit('click:search', this.formData)
    }
    // async initTheAllUser() {
    //   await this.$store.dispatch('USER_GET_ALL')
    // }
  }
}
</script>

<style src="./Toolbar.scss" lang="scss" scoped>
</style>
