var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    ref: "offday",
    attrs: {
      "width": "500"
    },
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', [_c('v-card-text', [_c('v-container', [_c('v-form', {
    ref: "form",
    attrs: {
      "lazy-validation": ""
    },
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('div', {
    staticClass: "py-4 my-0 mx-0"
  }, [_c('div', {
    staticClass: "text-center text-heading-1",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" 休暇追加 "), _c('div', [_c('v-btn', {
    staticClass: "close-button",
    attrs: {
      "depressed": "",
      "fab": "",
      "color": "#E27C7C",
      "width": "20",
      "height": "20"
    },
    on: {
      "click": _vm.closeModel
    }
  }, [_c('v-icon', {
    attrs: {
      "size": "14"
    }
  }, [_vm._v("mdi-close")])], 1)], 1)])]), _c('div', {
    staticClass: "m-0"
  }, [_c('div', {
    staticClass: "mt-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "form-header mb-2"
  }, [_vm._v("従業員")]), _c('div', [_c('v-select', {
    attrs: {
      "rules": _vm.userNameRule,
      "dense": "",
      "outlined": "",
      "items": _vm.allUsers,
      "item-value": "id",
      "placeholder": "従業員 id / お名前"
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(item.last_name) + " " + _vm._s(item.first_name) + " ")];
      }
    }, {
      key: "selection",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(item.last_name) + " " + _vm._s(item.first_name) + " ")];
      }
    }]),
    model: {
      value: _vm.formData.user_id,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "user_id", $$v);
      },
      expression: "formData.user_id"
    }
  })], 1)]), _c('div', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "form-header mb-2"
  }, [_vm._v("日付")]), _c('div', [_c('v-dialog', {
    ref: "offDaysDate",
    attrs: {
      "return-value": _vm.formData.date,
      "width": "290px"
    },
    on: {
      "update:returnValue": function updateReturnValue($event) {
        return _vm.$set(_vm.formData, "date", $event);
      },
      "update:return-value": function updateReturnValue($event) {
        return _vm.$set(_vm.formData, "date", $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref3) {
        var on = _ref3.on,
            attrs = _ref3.attrs;
        return [_c('v-text-field', _vm._g(_vm._b({
          attrs: {
            "readonly": "",
            "dense": "",
            "hide-details": "",
            "outlined": "",
            "placeholder": "0000 年 00 月 00 日"
          },
          model: {
            value: _vm.formData.date,
            callback: function callback($$v) {
              _vm.$set(_vm.formData, "date", $$v);
            },
            expression: "formData.date"
          }
        }, 'v-text-field', attrs, false), on))];
      }
    }]),
    model: {
      value: _vm.modal.offDaysDate,
      callback: function callback($$v) {
        _vm.$set(_vm.modal, "offDaysDate", $$v);
      },
      expression: "modal.offDaysDate"
    }
  }, [_c('v-date-picker', {
    attrs: {
      "day-format": _vm.formatDate,
      "scrollable": ""
    },
    model: {
      value: _vm.formData.date,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "date", $$v);
      },
      expression: "formData.date"
    }
  }, [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.modal.offDaysDate = false;
      }
    }
  }, [_vm._v(" キャンセル ")]), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.$refs.offDaysDate.save(_vm.formData.date);
      }
    }
  }, [_vm._v(" OK ")])], 1)], 1)], 1)]), _c('div', {
    staticClass: "mt-5",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "form-header mb-2"
  }, [_vm._v("申請形態")]), _c('div', [_c('v-select', {
    attrs: {
      "dense": "",
      "outlined": "",
      "items": _vm.offDayRange,
      "rules": _vm.offRangeRule,
      "item-text": "name",
      "item-value": "id",
      "placeholder": "申請形態を選択してください"
    },
    model: {
      value: _vm.formData.off_range,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "off_range", $$v);
      },
      expression: "formData.off_range"
    }
  })], 1)]), _c('div', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "form-header mb-2"
  }, [_vm._v("休暇申請ステータス")]), _c('div', [_c('v-select', {
    attrs: {
      "dense": "",
      "outlined": "",
      "rules": _vm.statusRule,
      "item-text": "name",
      "item-value": "id",
      "items": _vm.status,
      "placeholder": "ステータスを選択してください"
    },
    model: {
      value: _vm.formData.status,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "status", $$v);
      },
      expression: "formData.status"
    }
  })], 1)]), _vm.alert ? _c('v-col', {
    staticClass: "error--text pa-0",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" " + _vm._s(_vm.alertMessage) + " ")]) : _vm._e(), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "offset-6 text-right",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "white--text",
    attrs: {
      "color": "#4F55A7",
      "width": "100",
      "loading": _vm.loading
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("登 録 ")])], 1)], 1)], 1)], 1)], 1)])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }