const status = [
  {
    id: 0,
    name: '申請中'
  },
  {
    id: 1,
    name: '要相談'
  },
  {
    id: 2,
    name: '承認済'
  },
  {
    id: 3,
    name: '却下'
  }
]

const range = [
  {
    id: 0,
    name: '昼休'
  },
  {
    id: 1,
    name: '夜休'
  }
]

export default { status, range }
